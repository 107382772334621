.App {
    text-align: center;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-content: center;

    @media only screen and (max-width: 767px) {
        background-color: '#041f42';
    }
}

.site-title,
#footer-site-title {
  font-family: 'Damion', cursive;
  color: #ffcd00 !important;
  font-size: 32px;
  padding: 0 20px !important;
  justify-content: center;
}
#footer-site-title {
  font-size: 22px;
  font-weight: normal;
}

.dark {
  h1, h1#header, h2.header, form.ui.form.modeToggle, form.ui.form.modeToggle label, span.recharts-legend-item-text {
    color: #fff;
  }
  .userCard h2.header {
    color: #6c6c77;
  }
}

// Hero image
.hero {
  background-image: url('./assets/images/hackersalt.jpg');
  background-size: cover;
  height: 90vh;
  background-position: 50% 50%;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .cta {
    font-weight: bold;
    font-size: 18px;
    background: #fff;
    color: #041f42;
    border-radius: 50px;
    padding: 10px 20px;
    margin-bottom: 35px;
  }
}

nav .page-item {
  cursor: pointer;
}

// Make footer spread entire width
.footer .ui.inverted.vertical.segment {
  margin: 0 -15px !important;
}

.footer {
  margin-top: 50px;
}
.userCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    // text-decoration: none;
    color: rgb(108, 108, 119);
  }
.topContent {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    justify-content: center;
    @media (max-width: 995px){
        flex-direction: column-reverse;
        align-items: center;
    }
}
  .barChart {
    @media (max-width: 800px){
     display: none;
    }
  }
  .dark, .dark>* {
    color: rgb(230, 230, 230);
    // border: 1px solid red;
}

.lds-roller light {
    background: black;
}
  #header {
      padding: 20px 0px;
  }
  .modeToggle {
      text-align: right;
  }
  .loading {
      display: flex;
      justify-content: center;
  }
  .quote {
    margin: 0px 20px;
    max-width: 90%;
    // align-self: flex-start;
  }

  .rightContent {
    min-width: 150px;
  }

  .score {
    font-size: 1.4rem;
  }

  //TODO: add media breakpoint for mobile

